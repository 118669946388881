import { AxiosResponse } from "axios";
import { CODE_TO_COUNTRY } from "../../data/constants";
import api from "../api";

class SubscriptionsAPI {
  getAllSubscriptions = (country: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/subscriptions/?status=active&plan__country=${CODE_TO_COUNTRY[country]}`);
  };

  getFilteredSubscriptions = (filters: any): Promise<AxiosResponse<any>> => {
    let url = `v2/subscriptions/?status=active&plan__country=${CODE_TO_COUNTRY[filters.country]}`
    if (filters.category !== "" && filters.category !== "Todas") {
      url += `&plan__category=${filters.category}`
    }
    if (filters.search !== "") {
      url += `&search=${filters.search}`
    }
    return api.get(url);
  }

  getSubscriptionDetail = (id: string): Promise<AxiosResponse<any>> => {
    return api.get(`v2/subscriptions/${id}`);
  };

  deleteSubscriptionItem = (subscriptionId: string, subscriptionItemId: string): Promise<AxiosResponse<any>> => {
    return api.put(`v2/subscriptions/${subscriptionId}/update_subscription_item/`,
      {
        subscription_item_id: subscriptionItemId,
        active: false,
      });
  }

  editSubscriptionItem = (subscriptionId: string, data: object): Promise<AxiosResponse<any>> => {
    return api.put(`v2/subscriptions/${subscriptionId}/update_subscription_item/`, data);
  }

  addSubscriptionItem = (subscriptionId: string, data: object): Promise<AxiosResponse<any>> => {
    return api.post(`v2/subscriptions/${subscriptionId}/add_subscription_item/`, data);
  }

  editSubscription = (subscriptionId: string, data: object): Promise<AxiosResponse<any>> => {
    return api.put(`v2/subscriptions/${subscriptionId}/update_subscription/`, data);
  }

  addCoupon = (subscriptionId: string, coupon: object): Promise<AxiosResponse<any>> => {
    return api.post(`v2/subscriptions/${subscriptionId}/add_coupon/`, coupon);
  }

  editCoupon = (subscriptionId: string, coupon: object): Promise<AxiosResponse<any>> => {
    return api.put(`v2/subscriptions/${subscriptionId}/update_coupon/`, coupon);
  }
}

const subscriptionsAPI = new SubscriptionsAPI();
export default subscriptionsAPI;

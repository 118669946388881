import React, { useEffect, useState } from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import { navigate } from "gatsby";

import subscriptionsAPI from "../../utils/api/v2/subscriptions";
import Bold from "../Typography/Bold";
import Subscription from "../../utils/interfaces/Subscriptions";
import LoadingError from "../Loaders/LoadingError";
import SubscriptionsFilter from "./SubscriptionsFilter";
import api from "../../utils/api/api";

const TableWrapper = styled.div`
  margin: 0rem 3rem 3rem;
`;

type ActiveSubscriptionsProps = {
  country: "cl" | "mx"
}

const ActiveSubscriptions = (props: ActiveSubscriptionsProps) => {
  const [subscriptions, setSubscriptions] = useState([] as any[]) //ver type
  const [loading, setLoading ] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [totalRows, setTotalRows] = useState<number>(0)
  const [nextRequest, setNextRequest] = useState<string | null>(null);
  const [prevRequest, setPrevRequest] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);

      try {
        const req = await subscriptionsAPI.getAllSubscriptions(props.country);
        if (req.data.results.length > 0) {
          setSubscriptions(req.data.results)
          setTotalRows(req.data.count)
          setNextRequest(req.data.next?.replace("http", "https"))
          setPrevRequest(req.data.previous?.replace("http", "https"))
        } else {
          setError("No hay suscripciones activas")
        }
      } catch (err) {
        setError(err);
      }

      setLoading(false);
    };

    fetchData();
  }, []);

  const handleChangeSubscriptions = (subscriptions: Subscription[]): void => {
    setSubscriptions(subscriptions);
  }

  const fetchNewPage = async (action: "next" | "previous"): Promise<void> => {
    setLoading(true);
    try {
      const request = await api.request({
        method: "get",
        baseURL: "",
        url: action === "next" ? nextRequest : prevRequest,
      });
      setSubscriptions(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next?.replace("http", "https"));
      setPrevRequest(request.data.previous?.replace("http", "https"));
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const action = currentPage > newPage ? "previous" : "next";
    fetchNewPage(action);
    setCurrentPage(newPage);
  };

  const displayTableRows = (): Array<JSX.Element> => {
    return subscriptions.map((subscription: Subscription) => {
      return (
        <TableRow key={subscription.id} hover style={{cursor: 'pointer'}} onClick={() => {
          navigate(`/subscriptions/${subscription.id}/`);
        }}>
          <TableCell>{`${subscription.user.first_name} ${subscription.user.last_name}`}</TableCell>
          <TableCell>{`${subscription.plan.category} - ${subscription.plan.name}`}</TableCell>
        </TableRow>
      );
    });
  };

  return (
    <div className="py-4">
      <LoadingError loading={loading} />
      {error && <p>{error}</p>}
      {!loading && !error && (
        <div className="flex flex-col gap-y-10">
          <div className="self-center">
            <SubscriptionsFilter country={props.country} setSubscriptions={handleChangeSubscriptions}/>
          </div>
          <TableWrapper>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell><Bold>Usuario</Bold></TableCell>
                    <TableCell><Bold>Suscripción</Bold></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{displayTableRows()}</TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={totalRows}
                      rowsPerPage={20}
                      rowsPerPageOptions={[20]}
                      page={currentPage}
                      onPageChange={handleChangePage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </TableWrapper>
        </div>
      )}
    </div>
  )
};

export default ActiveSubscriptions;

import React, { useState, ChangeEvent } from "react"

import Button from "@material-ui/core/Button";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import styled from "styled-components";

import Subscription from "../../utils/interfaces/Subscriptions";
import subscriptionsAPI from "../../utils/api/v2/subscriptions";
import LoadingError from "../Loaders/LoadingError";
import { SUBSCRIPTIONS_CATEGORIES } from "../../utils/data/constants";

const Spaced = styled.div`
  margin: 0.5rem 1rem;
`;

const CategorySelect = styled(Select)`
  width: 200px;
  margin-bottom: 1rem;
`;


type SubscriptionsFilterProps = {
  country: "cl" | "mx";
  setSubscriptions: (subscriptions: Subscription[]) => void;
}

type FiltersType = {
  search: string;
  category: string;
  country: "cl" | "mx";
}

const SubscriptionsFilter = (props: SubscriptionsFilterProps) => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<FiltersType>({
    search: "",
    category: "",
    country: props.country,
  });

  function handlerOnChange(
      filter: "search" | "category",
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
      const value = e.target.value.toLowerCase().trim();
      setFilters({
        ...filters,
        [filter]: value,
      });
  }

  async function handleFilterSubscriptions() {
    setLoading(true);
    setError("");
    const req = await subscriptionsAPI.getFilteredSubscriptions(filters);
    if (req.data.results.length > 0) {
      props.setSubscriptions(req.data.results)
    } else {
      setError("No hay suscripciones para la búsqueda")
    }
    setLoading(false);
  }


  const categoryFilter = (): JSX.Element => {
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>Categoría</InputLabel>
          <CategorySelect
            autoWidth
            value={filters?.category}
            onChange={(e) => {
              setFilters({
                ...filters,
                category: e.target.value as string,
              });
            }}
          >
            {SUBSCRIPTIONS_CATEGORIES.map((category: string) => (
              <MenuItem value={category} key={category}>
                {category}
              </MenuItem>
            ))}
          </CategorySelect>
        </FormControl>
      </Spaced>
    );
  };

  return (
    <div className="flex flex-col lg:flex-row gap-x-5 items-center">
      <LoadingError loading={loading} />
      {!loading && error && <p>{error}</p>}
      {!loading && (
        <>
          <TextField
            fullWidth
            label="Rut, nombre o correo"
            variant="standard"
            onChange={(e) => handlerOnChange("search", e)}
            className="w-56"
            value={filters.search}
          />
          {categoryFilter()}
          <Button
            fullWidth
            variant="outlined"
            className="h-8 w-56 my-3 lg:my-0"
            color="primary"
            onClick={handleFilterSubscriptions}
          >
            Filtrar
          </Button>
        </>
      )}
    </div>
  )
}

export default SubscriptionsFilter
